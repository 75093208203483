import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'

import pt from '../assets/images/minerals/periodic-table.png'

const MS_IMAGES = [
    { id: '1', src: pt, thumbnail: pt, caption: 'Available Minerals'}
];

const siteTitle = "Mineral Supplies from South Africa | The Fu Foundation"
const siteDescription = "Exporting Mineral Resources such as Chromite Ore, Manganese Ore, Copper Ore and Iron Ore from South Africa."

const Mineral = (props) => (
    <Layout>
        <Helmet>
            <title>{siteTitle}</title>
            <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main" className="alt">
            <section id="mineral-supplies">
                <div className="inner">
                    <header className="major">
                        <h1>Mineral Supplies</h1>
                    </header>
                    <p><i>We are expanding our business horizons to also include the supplying of mineral resources</i>.</p>
                    <p>We currently supply the following minerals: Chromite Ore, Manganese Ore, Iron Ore, and Copper Ore. All these mineral resources will be exported from South Africa.</p>

                    <Gallery images={MS_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                        src,
                        thumbnail,
                        caption,
                        description
                    }))} />

                    <div>
                        <h3>Chromite Ore</h3>
                        <p>This brownish-black oxide of chromium and iron has the chemical structure of FeCr₂O₄, usages include Refactory, Casting, Blasting, Grinding, Lapping in foundry and steel industries.</p>
                    </div>

                    <div>
                        <h3>Manganese Ore</h3>
                        <p>This silver-gray brittle metal has the atomic number of 25, can be used to increase the hardness of steel.</p>
                    </div>

                    <div>
                        <h3>Copper Ore</h3>
                        <p>This chemical element can be mined to conduct electricity.</p>
                    </div>

                    <div>
                        <h3>Iron Ore</h3>
                        <p>This type of ore is being used to produce steel.</p>
                    </div>
                </div>
            </section>

{/* DEVNOTE: refactor this later, remove duplicate code */}
            <section id="contact-us">
                <h2>Get In Touch</h2>
                <p>Should you require additional information regarding our developments, rental stocks, or the exporting of minerals, please feel free to send us an email. Alternatively, complete the form below and we will get back to you.</p>
                <div className="row">
                    <div className="8u 12u$(small)">
                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                            <input type="hidden" name="bot-field" />
                            <div className="row uniform 50%">
                                <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                            </div>
                            <br/>
                            <ul className="actions">
                                <li><input type="submit" value="Send Message" /></li>
                                <li><input type="reset" value="Clear" /></li>
                            </ul>
                        </form>
                    </div>
                    <div className="4u 12u$(small)">
                        <ul className="labeled-icons">
                            <li>
                                <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                13 Chester Rd.<br />
                                Parkwood, Johannesburg<br />
                                South Africa
                            </li>
                            <li>
                                <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                <a href="mailto:info@thefufoundation.com" target="_top">info@thefufoundation.com</a><br/>
                                <a href="mailto:rental@thefufoundation.com" target="_top">rental@thefufoundation.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>       
        </div>

    </Layout>
)

export default Mineral